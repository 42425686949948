<template>
  <SidebarComponent ref="sidebar" title="Importar">
    <p>
      Faça upload ou uma URl válida e pública para compor seus arquivos nos diferentes formatos
      png, jpg, mp4, etc.
    </p>
    <DropZoneComponent @upload="handleImage(...arguments)"></DropZoneComponent>
    <!--<small>
      Tamanho de arquivo máximo recomendado de 768 x 1024 px, (2 MB para imagens e 10 MB para
      vídeo), para a miniatura de mídia é 770 x 335 pixels (40 KB).
    </small>-->
    <div class="img-wrapper-preview" v-if="image">
      <p>Preview</p>
      <img v-if="format === 'IMAGE'" :src="image" alt="" srcset=""/>
      <video v-else-if="format === 'VIDEO'" :src="image" alt="" srcset=""/>
      <object v-else :src="image" alt="" srcset=""/>
    </div>
    <InputComponent v-model="url" class="m-t-25" placeholder="Link URL" @keyUp="handleInputValue(...arguments)"></InputComponent>
    <template #footer>
      <div class="d-flex justify-content-between">
        <ButtonComponent @click="close()" class="m-r-40" variant="secondary" :loading="isSending">Cancelar</ButtonComponent>
        <ButtonComponent @click="nextStep">Próximo</ButtonComponent>
      </div>
    </template>
  </SidebarComponent>
</template>

<script>
import axios from '@/plugins/axios';
import validationRules from '@/rules';
import SidebarComponent from '@/components/SidebarComponent.vue';
import DropZoneComponent from '@/components/form/DropZoneComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import BucketService from '@/services/bucket.service';

const webApi = axios();

export default {
  name: 'FileUploadComponent',
  components: {
    ButtonComponent,
    SidebarComponent,
    DropZoneComponent,
    InputComponent,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'IMAGE',
    },
    options: {
      type: Array,
      default: () => [],
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    value: {
      type: [String, Array, Object],
      default: '',
    },
    placeholder: {
      type: String,
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      allRules: [],
      tokens: {
        '#': { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        '!': { escape: true },
      },
      Deselect: {
        render: (createElement) => createElement('span', 'close'),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', 'expand_more'),
      },
      url: '',
      image: null,
      file: null,
      uploadProgress: 0,
      isSending: false,
    };
  },
  computed: {
    classObject() {
      return [
        this.variant,
      ];
    },
    isValid() {
      return this.errors.length === 0;
    },
  },
  created() {
    this.allRules = this.rules;
    if (this.required) {
      this.allRules.push('required');
    }
    if (this.type === 'email') {
      this.allRules.push('email');
    }
    if (this.type === 'tel') {
      this.allRules.push('tel');
    }
  },
  methods: {
    handleInputValue(event) {
      // console.log('Teste do teste: ', event);
      this.url = event;
      this.file = null;
      this.image = '';
    },
    handleImage(event) {
      // const reader = new FileReader();
      this.file = event.target?.files[0];
      this.image = window.URL.createObjectURL(this.file);
      this.url = '';
      // reader.readAsDataURL(file);
      // this.image = event[0].imgUrl;
      // console.log('Files:: ', event, '\nTo bucket: ', form);
    },
    uploadFile() {
      const form = new FormData();
      console.log('Name: ', this.file);
      form.append('files[]', this.file);
      form.append('role', 'public');
      form.append('folder', 'whatsapp');
      form.append('account_id', this.$store.state.account.id);
      console.log('Bucket:: ', form, '\nFile: ', this.file.name);
      BucketService.upload(form, true, (progress) => {
        this.uploadProgress = progress;
      }).then((responses) => {
        const url = `${responses.data[0].url}.${responses.data[0].extension}`;
        console.log('Bucket URL: ', url);
        this.$emit('import', url);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.uploading = false;
      });
    },
    open() {
      this.$refs.sidebar.show();
    },
    close() {
      this.$refs.sidebar.hide();
    },
    nextStep() {
      if (this.url) {
        this.$emit('import', this.url);
        this.$refs.sidebar.hide();
      }
      if (this.file) {
        this.uploadFile();
        this.$refs.sidebar.hide();
      }
    },
    handleClick(e) {
      this.$emit('click', e);
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    handleBlur(event) {
      this.validate(event.target.value);
      this.$emit('input', event.target.value);
    },
    handleChange(event) {
      this.validate(event.target.value);
      this.$emit('input', event.target.value);
    },
    validate(value) {
      if (this.allRules.length) {
        this.errors = [];
        this.allRules.forEach((rule) => {
          rule = rule.split(':');
          if (validationRules[rule[0]]) {
            if (!validationRules[rule[0]].condition(value, rule[1])) {
              const message = validationRules[rule[0]].getMessage();

              if (message) {
                this.errors.push(this.$t(message, [rule[1]]));
              } else {
                this.errors.push('');
              }
            }
          }
        });
        // Validate mask
        if (this.mask && !this.errors.length) {
          if (value.length !== this.mask.length) {
            const message = validationRules.mask.getMessage();
            this.errors.push(this.$t(message));
          }
        }
      }
    },
    checkValidation() {
      this.validate(this.value);
    },
    maskIt(value) {
      if (this.mask) {
        value = value || '';
        let iMask = 0;
        let iValue = 0;
        let output = '';
        while (iMask < this.mask.length && iValue < value.length) {
          let cMask = this.mask[iMask];
          const masker = this.tokens[cMask];
          const cValue = value[iValue];
          if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
              output += masker.transform ? masker.transform(cValue) : cValue;
              iMask += 1;
            }
            iValue += 1;
          } else {
            if (masker && masker.escape) {
              iMask += 1; // take the next mask char and treat it as char
              cMask = this.mask[iMask];
            }
            output += cMask;
            if (cValue === cMask) iValue += 1; // user typed the same char
            iMask += 1;
          }
        }
        return output;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  border: 2px dashed #6F6F6F;

  .dz-message {
    padding: 97px 51px;
    margin: 0;

    .material-icons-outlined {
      font-size: 43px;
      color: #2494FF;
    }
  }
}
.img-wrapper-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 0rem;
  p {
    font-size: 1.25rem;
    width: 100%;
  }
  img, video, object {
    width: 220px;
  }
}
</style>
