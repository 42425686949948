<template>
  <div class="whatsapp360">
    <!-- <pre>{{resume.whatsapp}}</pre> -->
    <m-crop id="m-crop-whatsapp-component" />
    <div :class="{ 'd-none': resume.sending !== 'none' }">
      <div>
        <template v-if="!whatsAppActive">
          <div class="py-4 text-center">WhatsApp intativo</div>
        </template>
        <div class="text-left pb-4" v-else>
          <select-input
            class="yu-whatsapp"
            label="name"
            :reduce="(template) => template"
            :options="whatsAppTemplates"
            :clearable="false"
            v-model="whatsAppTemplate"
            v-if="whatsAppTemplates.length" />
          <template v-else>
            <b-spinner type="grow" small></b-spinner>
            <b-spinner type="grow" small></b-spinner>
            <b-spinner type="grow" small></b-spinner>
          </template>
          <div class="whatsapp-bg" v-if="resume.whatsapp.form.body">
            <div class="whatsapp-message">
              <div class="whatsapp-preview p-4 card">
                <template v-if="resume.whatsapp.form.header">
                  <header v-if="resume.whatsapp.form.header.format === 'TEXT'">
                    {{resume.whatsapp.form.header.text}}
                  </header>
                  <div class="img-wrapper" v-else-if="resume.whatsapp.form.template.header.format === 'IMAGE'">
                    <img id="img-preview">
                  </div>
                </template>
                <p v-html="whatsAppBody"></p>
                <footer v-if="resume.whatsapp.form.footer">
                  {{resume.whatsapp.form.footer.text}}
                </footer>
              </div>
              <template v-if="resume.whatsapp.form.template.buttons">
                <div v-for="(btn, i) in resume.whatsapp.form.template.buttons" :key="i">
                  <button
                    type="button"
                    class="btn-link-preview"
                    v-if="btn.type == 'PHONE_NUMBER'"
                    disabled
                  >
                    <i class="fas fa-phone"></i> {{btn.text}}
                  </button>
                  <button
                    type="button"
                    class="btn-link-preview"
                    v-if="btn.type === 'URL'"
                    disabled
                  >
                    <i class="fas fa-link"></i> {{btn.text}}
                  </button>
                  <button
                    type="button"
                    class="btn-link-preview"
                    v-if="btn.type === 'QUICK_REPLY'"
                    disabled
                  >
                    {{btn.text}}
                  </button>
                </div>
              </template>
            </div>
          </div>
          <div class="row" v-if="resume.whatsapp.form.template.header">
            <div class="col-lg-12">
              <input
                type="file"
                accept="video/*"
                class="form-control"
                :class="{ 'd-none': resume.whatsapp.form.template.header.format !== 'VIDEO' }"
                @change="selectFile">
              <input
                type="file"
                accept="application/pdf"
                class="form-control"
                :class="{ 'd-none': resume.whatsapp.form.template.header.format !== 'DOCUMENT' }"
                @change="selectFile">
              <!-- <input
                type="file"
                accept="image/jpeg,image/png"
                class="form-control"
                :class="{ 'd-none': headerType !== 'IMAGE' }"
                @change="selectFile"> -->
              <div v-if="resume.whatsapp.form.template.header.format === 'IMAGE'">
                <button
                  class="btn btn-primary"
                  @click="callCrop('img-preview', 500, 280, resume.whatsapp.form.template.header)">
                  Escolher JPG ou PNG
                </button>
              </div>
              <hr>
            </div>
          </div>
          <div class="row" v-if="resume.whatsapp.form.params">
            <div
              class="col-lg-6"
              v-for="param in resume.whatsapp.form.params"
              :key="param.index"
            >
              <label>Parâmetro {{param.index}}</label>
              <input class="form-control" type="text" v-model="param.text"/>
            </div>
          </div>
        </div>
      </div>
      <div class="py-2" v-if="room.fromOffline">
        <input
          class="tgl tgl-light tgl-primary markassolved-modal-change"
          id="markassolved-modal-active"
          type="checkbox"
          v-model="markAsSolvedAfter"
        />
        <label class="tgl-btn" for="markassolved-modal-active"></label> Marcar como resolvido
      </div>
      <div class="py-2">
        <button
          class="btn btn-primary btn-icon --btn-outline-primary"
          style="margin-bottom: 100px;"
          type="button"
          @click="sendResumeWhatsApp()"
          v-if="whatsAppActive"
        >
          Enviar WhatsApp
        </button>
      </div>
    </div>
    <div class="p-4 text-center" v-if="resume.sending === 'sending'">
      <b-spinner type="grow" small></b-spinner>
      <b-spinner type="grow" small></b-spinner>
      <b-spinner type="grow" small></b-spinner>
    </div>
    <div v-else-if="resume.sending === 'done'">
      <div class="card">
        <div class="card-body p-4 text-center">
          Solicitação de contato enviado com sucesso!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import SelectInput from '@/components/SelectInput.vue';
import BucketService from '@/services/bucket.service';

import axios from '@/plugins/axios';
import MCrop from '@/components/MCrop.vue';

export default {
  name: 'OmniInfo',
  components: {
    SelectInput,
    MCrop,
  },
  props: ['room'],
  data() {
    return {
      bodyParams: [],
      uploading: false,
      uploadProgress: false,
      markAsSolvedAfter: false,

      whatsAppTemplates: [],
      whatsAppTemplate: null,
      whatsAppActive: false,

      resume: {
        sending: 'none',
        whatsapp: {
          form: {
            clientId: '',
            type: 'simple',
            from: 'Default',
            to: '',
            body: '',
            file: '',
            normalize: false,
            flash: false,
            template: {},
            params: null,
          },
        },
      },
      cropPreview: null,
      cropActive: null,
      file: null,
      headerVideo: null,
      headerDocument: null,
      headerImage: null,
    };
  },
  filters: {
    fullDate(data) {
      return new Date(data).toLocaleString();
    },
  },
  computed: {
    templateBodyParams() {
      const body = this.whatsAppBody;

      return body && [...body?.text?.matchAll(/\{\{\d+}\}/g)].map((param, index) => {
        if (body.example_parameters[index]) return body.example_parameters[index];

        return `Parametro {{${index + 1}}}`;
      });
    },
    bodyPreview() {
      return this.whatsAppBody.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] || param;
      });
    },
    whatsAppBody() {
      const form = this.resume.whatsapp?.form;

      if (!form?.body) return '';

      const params = form.params;

      return form.body.replace(/\{\{(.*?)\}\}/g, (original, index) => {
        const entry = params.find((param) => param.index === parseInt(index, 10));
        return entry?.text || '';
      });
    },
    headerCurrentVideo() {
      if (this.resume.whatsapp.form.template.header.format === 'VIDEO') {
        return this.headerVideo;
      }

      return null;
    },
    headerCurrentDocument() {
      if (this.resume.whatsapp.form.template.header.format === 'DOCUMENT') {
        return this.headerDocument;
      }

      return null;
    },
    headerType() {
      return this.resume.whatsapp.form.template.header.format || null;
    },
    headerCurrentImage() {
      if (this.resume.whatsapp.form.template.header.format === 'IMAGE') {
        return this.headerImage;
      }

      return null;
    },
  },
  mounted() {
    this.bootWhatsAppTemplates();
    this.$root.$on('call.crop', this.crop);
  },
  watch: {
    whatsAppTemplate(newVal) {
      this.insertTemplate(newVal);
    },
  },
  methods: {
    crop(data) {
      this.cropActive = data;
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        this.file = data;
        document.getElementById(this.cropPreview).src = `${this.file.url}`;
      }
      if (data) this.uploadFile(data);
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, cropActive) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.$root.$emit('show.modal', 'm-crop-whatsapp-component');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    selectFile(event) {
      const file = event.target?.files[0];

      console.log('selectFile', { selectFile: file });

      if (file) this.uploadFile(file);
    },
    uploadFile(handle) {
      const format = this.resume.whatsapp.form.template.header.format;

      console.log('uploadFile', {
        handle,
        format,
      });

      if (!handle || !format || format === 'TEXT') return;

      const form = new FormData();

      let file;

      if (format === 'IMAGE') {
        const crop = JSON.stringify([{
          x: handle.x,
          y: handle.y,
          w: handle.width,
          h: handle.height,
        }]);

        form.append('crop', crop);

        file = handle.file;
      } else {
        file = handle;
      }

      form.append('files[]', file);
      form.append('role', 'public');
      form.append('folder', 'whatsapp');
      form.append('account_id', this.$store.state.account.id);

      this.uploading = true;

      BucketService.upload(form, true, (progress) => {
        this.uploadProgress = progress;
      }).then((responses) => {
        const url = responses.data[0].url;

        if (format === 'IMAGE') {
          this.headerImage = url;
        } else if (format === 'VIDEO') {
          this.headerVideo = url;
        } else if (format === 'DOCUMENT') {
          this.headerDocument = url;
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.uploading = false;
      });
    },
    validForm() {
      console.log('validForm', {
        headerType: this.headerType,
        'this.bodyResults?.indexOf(null)': this.bodyResults?.indexOf(null),
      });
      return this.bodyResults?.indexOf(null) === -1 && (
        !this.headerType ||
        (this.headerType === 'TEXT' && this.headerPreview.indexOf('{{1}}}') === -1) ||
        (this.headerType === 'IMAGE' && this.headerCurrentImage) ||
        (this.headerType === 'VIDEO' && this.headerCurrentVideo) ||
        (this.headerType === 'DOCUMENT' && this.headerCurrentDocument)
      );
    },
    /*
    sendMessage() {
      const template = this.resume.whatsapp.form.template;
      const markAsSolved = this.markAsSolvedAfter;
      const room = this.$parent.actualRoom;
      const mobileNumber = room?.contact?.mobileNumber;

      if (!mobileNumber) {
        console.error('sendResumeWhatsApp erro', room);
        return;
      }

      this.resume.sending = 'sending';

      let request = null;

      if (true) {
        const form = {
          button_parameter: null,
          client_number: mobileNumber.replace(/\D+/g, ''),
          header_parameter: null,
          header_type: null,
          language_code: template.language,
          template_name: template.name,
          business_id: template.business_id,
          body_parameters: this.bodyParams,
          type: 'template',
        };

        console.info('send whatsapp', { form, template });
        console.log(form);
        request = axios().post('whatsapp/new/send', form);
      } else {
        const form = this.resume.whatsapp.form;

        form.agentId = this.$live.myUserId;
        form.clientId = room.clientId;
        form.phone = mobileNumber;

        console.log('sendResumeWhatsApp 360', form);

        request = axios().post('webchat/resume/whatsapp', form);
      }

      request.then((response) => {
        this.resume.sending = 'done';
        this.reqTriggerScroll();

        if (markAsSolved) this.$live.markAsSolved(room);
      }).catch((ee) => {
        this.resume.sending = 'none';

        this.$toast.show({
          title: 'Falha ao enviar WhatsApp',
          content: String(ee),
          type: 'danger',
        });
      });
    },
    */
    bootWhatsAppTemplates() {
      this.whatsAppTemplates = [];

      // WhatsAppService.getClient().then((response) => {
      //   const active = response.data.active || false;

      //   this.whatsAppActive = active;

      //   if (active) this.fetchWhatsAppTemplates();
      // }).catch((ee) => {
      //   this.$toast.show({
      //     title: 'Conta WhatsApp',
      //     content: String(ee),
      //     type: 'danger',
      //   });
      // });

      this.whatsAppActive = true;

      this.fetchWhatsAppTemplates();
      // this.simulateWhatsAppTemplates();
    },
    fetchWhatsAppTemplates() {
      axios().post('whatsapp/templates/list').then((response) => {
        const templates = response?.data?.data?.['waba_templates'];
        console.log('fetchWhatsAppTemplates', templates, response.data);

        if (templates) {
          this.parseWhatsAppData(templates);
        } else {
          this.$toast.show({
            title: 'Templates WhatsApp',
            content: 'Formato inesperado do servidor',
            type: 'danger',
          });
        }
      }).catch((ee) => {
        this.$toast.show({
          title: 'Templates WhatsApp',
          content: String(ee),
          type: 'danger',
        });
      });
    },
    parseWhatsAppData(templates) {
      this.whatsAppTemplates = templates.filter((template) => {
        if (template.status !== 'approved') return false;

        return true; // return template.components.findIndex((component) => component.format && component.format !== 'TEXT');
      });
    },
    insertTemplate(template) {
      const form = this.resume.whatsapp.form;

      form.template = {
        header: {},
      };
      form.template.name = template.name;

      template.components.forEach((element) => {
        form.template.type = 'text';

        switch (element.type) {
          case 'HEADER':
            console.log('Header temp');
            console.log(template);
            if (element.type === 'HEADER' && element.format === 'IMAGE') {
              form.template.header.type = 'IMAGE';
              // headerCurrentImage
            }
            break;
          case 'FOOTER':
            //  form.template.header = element;
            form.template.type = 'rich';

            if (element.type === 'HEADER' && element.format === 'IMAGE') {
              form.template.format = 'image';
            }
            break;
          case 'BODY':
            form.template.body = element.text;
            form.body = element.text;
            break;
          case 'BUTTONS':
            form.template.buttons = element.buttons;
            form.template.type = 'rich';
            break;
          default:
            console.info(element);
        }
      });

      const params = form.template.body.match(/\{\{(.*?)\}\}/g);

      if (params) {
        form.params = params.map((_, index) => ({ index: index + 1, text: '' }));
      } else {
        form.params = null;
      }

      console.log('Template selecionado: ', this.resume.whatsapp.form);
    },
    sendResumeWhatsApp() {
      const room = this.room;
      const markAsSolved = this.markAsSolvedAfter;
      const mobileNumber = room?.contact?.mobileNumber;
      console.log(this.resume.whatsapp.form);
      if (!mobileNumber) {
        console.error('sendResumeWhatsApp', room);
        return;
      }

      this.resume.sending = 'sending';

      let request = null;
      let headerParam = null;

      const form = this.resume.whatsapp.form;

      form.agentId = this.$live.myUserId;
      form.clientId = room.clientId;
      form.phone = mobileNumber;
      form.to = mobileNumber;

      if (this.headerType === 'TEXT') {
        headerParam = (
          this.headerParam === '' || this.headerParam === false || this.headerParam === null
        ) ? null : this.headerParam;
      } else if (this.headerType === 'VIDEO') {
        form.template.header.type = 'HEADER';
        headerParam = this.headerCurrentVideo;
      } else if (this.headerType === 'DOCUMENT') {
        form.template.header.type = 'HEADER';
        headerParam = this.headerCurrentDocument;
      } else if (this.headerType === 'IMAGE') {
        form.template.header.type = 'HEADER';
        headerParam = this.headerCurrentImage;
      }

      form.template.header.format = this.headerType;

      if (headerParam !== null) {
        // form.template.header.header_type = this.headerType;
        form.template.image = headerParam;
        form.template.type = 'rich';
      }

      console.log('sendResumeWhatsApp', form);

      request = axios().post('webchat/resume/whatsapp', form);

      request.then((response) => {
        this.resume.sending = 'done';
        // this.$parent.reqTriggerScroll();

        if (markAsSolved) this.$live.markAsSolved(room);
      }).catch((ee) => {
        this.resume.sending = 'none';

        this.$toast.show({
          title: 'Falha ao enviar WhatsApp',
          content: String(ee),
          type: 'danger',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.omni-edit-contact {
  .qt-loader {
    &::before {
      display: none;
    }

    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-copy {
  padding: .2rem;
}

.live-info {
  .field-edit {
    .material-icons-outlined {
      font-size: 1rem;
      vertical-align: middle;
      color: #752de6 !important;
    }
  }
}

.info {
  height: 100%;

  &.live-meta-show {
    display: block;
  }

  &::v-deep .live-meta-tabs {
    height: calc(100% - 120px);

    .material-icons-outlined {
      font-size: 1.2rem;
    }

    .tab-content {
      height: calc(100% - 52px);
    }
  }

  &::v-deep .tab-pane {
    height: 100%;
  }

  &::v-deep .live-meta-tabs {
    .nav-tabs {
      margin: 0 auto;
      width: 230px;
      border: none !important;
    }

    .nav-tabs {
      padding: 1rem 0;

      .nav-item {
        text-align: center;

        .nav-link {
          border-radius: 19px;
          background: transparent;
          padding: 10px 0 0 0;
          width: 38px;
          height: 38px;
          margin: 0 auto;

          .material-icons-outlined, i {
            color: #752de6 !important;
          }

          &.active, &:hover {
            border: none !important;
            background-color: rgba(116, 45, 230, .19);
          }
        }
      }
    }
  }

  .session {
    margin-right: 8px;
    display: inline-block;
    background: #ccc;
    border-radius: 9px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  .session-time {
    vertical-align: middle;
  }

  @media (max-width: 1230px) {
    // box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    flex: 0 0 0;
    z-index: 100;
    position: fixed;
    top: 62px;
    right: 0;
    width: 0;

    & > * {
      min-width: 300px;
    }

    &.live-meta-show {
      box-shadow: 0 22px 25px 9px rgba(0,0,0,.2);
      height: calc(100% - 62px);
      width: 300px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }

  .live-photo {
    padding: 0 !important;
    margin: 0 15px 0 0;
    background: #ccc;
    height: 72px;
    width: 72px;
    border: none;
    border-radius: 50%;
    font-size: 32pt;
    margin-bottom: 5px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: 40px;
    overflow: hidden;

    .material-icons-outlined {
      font-size: 1.5rem;
    }

    &, * {
      text-align: center;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.live-header-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  width: 100%;
  font-size: 13pt;
  font-weight: bold;
  margin: 0 auto;
}

.live-meta-header {
  & > div:first-child,
  & > div:first-child + div {
    height: 100%;
  }

  & > div:first-child {
    width: 60px;
    float: left;
    text-align: center;
    padding-top: 22px;
  }

  & > div:last-child {
    text-align: center;
  }

  & > div:first-child + div {
    float: right;
    width: 42px;
  }
}

.live-meta-header {
  height: auto !important;
  position: relative;
  text-align: center;
  border: none !important;
}

.live-no-files {
  padding: 2em;
  text-align: center;
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-grid-files {
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 7px);

  h4 {
    font-weight: normal !important;
  }

  .files {
    margin-right: -5px;
    margin-left: -5px;

    & > div {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .col-4 {
    height: 100px;
    margin-bottom: .5rem;

    /*
    &:first-child button {
      border-radius: 14px 0 0 0;
    }

    &:first-child button {
      border-radius: 14px 0 0 0;
    }
    */

    & ~ .live-no-files {
      display: none;
    }
  }

  a, button {
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    background: #a0a0a0;
    display: block;
    cursor: pointer;

    & > img, & > audio, & > video {
      object-fit: cover;
    }

    & > img, & > audio, & > video, .live-file-doc {
      min-width: 100%;
      min-height: 100%;
      pointer-events: none;
    }

    .live-file-doc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24pt;
      font-weight: bold;
      text-transform: uppercase;
    }

    & > i {
      font-size: 80%;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0,0,0,.2);
      position: absolute;
      bottom: 5px;
      left: 5px;
    }

    & > span {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,.2);
      width: 100%;
      height: auto;
      padding: .5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 70%;
      text-align: right;
      overflow: hidden;
      direction: rtl;
      opacity: 0.001;
      color: #fff;
      font-weight: bold;
      transition: ease-out .2s;
      pointer-events: none;
    }

    &:hover > span {
      opacity: .99;
    }
  }
}

.live-info {
  padding: 14px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  font-size: .92rem;

  h3 {
    font-size: .92rem;
    font-weight: normal;
    margin: 0;
  }

  dl {
    padding: .4em 0;
    margin: 0;

    dt {
      font-size: 10pt;
    }

    dd {
      margin-bottom: 2em;
      padding: .2em .2em .2em .2em;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &, > * {
        font-size: 10pt;
      }
    }

    .copy {
      input {
        padding: 0;
        background: transparent;
        border: none;
        margin-right: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      button {
        border-radius: 50px !important;
        border: thin solid #ccc;
      }
    }
  }
}

.live-history-item {
  padding: 0;
  margin: .4em 1em .4em 0;
  position: relative;
  width: 97%;
  text-align: left;
  transition: .15s all ease;

  h4 {
    font-weight: normal;
    font-size: 9pt;
  }

  .copy {
    position: absolute;
    right: .2rem;
    top: .2rem;
    padding: .2rem .5rem;
  }

  .item {
    text-align: left;
    padding: .6em;
    background: transparent;
    border: none;

    &:hover {
      background: #ececec;
    }
  }

  p {
    margin: 0;
    padding: .05em 2em .05em 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  time {
    font-size: 80%;
    color: #c0c0c0;
    display: block;
  }

  .channel {
    border: thin solid #ccc;
    border-radius: 50px;
    height: 32px;
    width: 32px;
    position: absolute;
    right: -16px;
    top: 25%;
    background: #fff;
    text-align: center;

    & + p + p {
      color: #a1a1a1;
    }

    i {
      position: relative;
      margin-top: 50%;
      width: 16px;
      height: 16px;
      top: -8px;
    }
  }
}

.live-old-history {
  border: solid #f7d85c;
  border-width: thin 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #ffefad;

  .items {
    height: 60px;
  }

  .item-left {
    position: relative;

    & > i {
      font-size: 18pt;
    }
  }

  .item-right {
    border-bottom: none;
  }

  * {
    color: #bb9600;
  }
}

.live-history-item {
  &.active {
    border-color: #f7d85c;
    background: #ffefad;

    &:hover {
      background: #fde996;
    }

    * {
      color: #bb9600;
    }

    .channel {
      border-color: #bb9600;
    }
  }
}

.live-meta-close {
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16pt;

  @media (max-width: 1230px) {
    display: block;
  }
}

.material-icons-outlined {
  color: #788db4 !important;
}

.whatsapp-bg {
  background: #efeae2 url('/assets/img/chat-bg.png');
  margin: 1.25rem -1.25rem;
  padding: 1rem;
  pointer-events: none;
}

.whatsapp-message {
  display: inline-block;
  max-width: 65%;
  min-width: 224px;
}

.whatsapp-preview {
  margin: 0 0 .1rem 0;

  & > p {
    margin: 0;
  }
}

.qt-loader {
  text-align: center;
  padding: 32px;
}

.recording-write {
  text-align: center;

  &::v-deep .spinner-grow {
    margin: 0 .22em;
  }
}

.live-resume-contact {
  margin-top: 1em;

  &::v-deep > .card > .tabs {
    & > .card-header {
      padding-bottom: 0;

      & > .nav {
        margin-bottom: 0;
      }
    }
  }
}

.live-header-contact {
  margin-top: .32rem;
  font-size: .8rem;
}

.live-media-preview {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;

  video {
    background: #333;
  }

  & > * {
    pointer-events: none;
    min-height: 16px;
    min-width: 16px;
    display: block;
  }
}

.live-conversation {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.live-old-load {
  padding: 1em;
  text-align: center;
}

.live-transcript {
  height: calc(100% - 172px);
  overflow: hidden;
  overflow-y: auto;

  &.readonly, &.live-transcript-old {
    height: calc(100% - 80px);
    background: url('data:image/svg+xml,%3Csvg width="220px" height="220px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">%3Cg fill="%23eee">%3Cpath fill="none" d="M0 0H24V24H0z"/>%3Cpath d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/>%3C/g>%3C/svg>') bottom right no-repeat;
  }
}

.live-transcript-old + .live-transcript {
  height: 0 !important;
  padding: 0 !important;
}

.live-manager-actions {
  position: relative;
  height: 92px;
  text-align: right;

  &.live-resume-actions {
    width: 204px;
    position: absolute;
    bottom: 0;
    right: .5em;
    z-index: 1000;
  }

  width: 204px;
  position: absolute;
  bottom: 0;
  right: .5em;

  .live-manager-enter {
    color: #fff;
    background: #0E1421;
    padding: 1em 2em;
    border-radius: 50px;
    border: none;
    font-size: 9pt;
    display: block;
    width: 98%;
    height: 40px;
    white-space: nowrap;

    & ~ .live-manager-enter {
      margin-top: .3em;
    }

    &, i {
      vertical-align: middle;
    }

    i {
      font-size: 13pt;
      margin-right: .5em;
      color: #fff;
    }
  }
}

.live-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: normal;
  align-items: center;
  border: thin solid #e8e4e4;
  background: #fff;
  padding: 0 .5em;
  position: relative;
  z-index: 90;

  .material-icons-outlined {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  & > * {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 40px;
    align-self: auto;
    order: 0;
    height: 45px;

    @media (max-width: 480px) {
      flex-basis: 45px;
    }
  }

  .live-file-preview {
    position: absolute;
    z-index: 90;
    left: 0;
    bottom: 90px;
    width: 100%;
    height: 60vh;
    max-height: 640px;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: #fff;
    border: 0 solid #e8e4e4;
    border-top-width: thin;
    box-shadow: 0 -31px 31px -22px rgba(0,0,0,.2);

    aside {
      min-width: 200px;

      img, audio, video {
        display: block;
        margin: 0 auto;
      }
    }

    &.live-record-audio {
      bottom: 0;
      z-index: 91;

      .record {
        text-align: center;

        .timer {
          font-size: 9pt;
        }

        .audio-finish {
          margin: 10px 0;
          border-radius: 48px;
          background: #f00;
          border: none;
          height: 92px;
          width: 92px;
          font-size: 32pt;
          box-shadow: 0 0 0 rgba(0,0,0,.02), 0 0 0 0 rgba(0,0,0,.06);
          animation-duration: .4s;
          animation-name: audioWave;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          i, & {
            color: #fff !important;
          }

          @keyframes audioWave {
            from {
              box-shadow: 0 0 0 0 rgba(0,0,0,.02), 0 0 0 0 rgba(0,0,0,.06);
            }

            to {
              box-shadow: 0 0 0 8px rgba(0,0,0,.2), 0 0 0 4px rgba(0,0,0,.2);
            }
          }
        }

        footer {
          font-size: 7pt;
        }

        .row {
          padding: 20px 0;
        }

        .row > div {
          padding: 0;
        }

        .audio-cancel, .audio-send {
          width: 100%;
        }
      }
    }

    .card-header {
      height: 72px;

      button {
        background: transparent;
        border: none;
        padding: 0;

        i {
          font-size: 16pt;
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 72px);
      position: relative;

      aside {
        img, object, video {
          position: relative;
          border: thin solid #e8e4e4;
          object-fit: contain;
          height: 100%;
          max-height: 240px;

          @media (max-height: 500px) {
            max-height: 150px;
          }

          @media (max-height: 420px) {
            max-height: 110px;
          }
        }

        .d-flex {
          @media (max-height: 420px), (max-width: 240px) {
            font-size: 8pt;
          }
        }
      }
    }
  }

  .live-file-preview-meta {
    font-size: 9pt;
  }

  .live-input-out {
    flex-grow: 1;
    flex-basis: auto;
    // height: 52px;
    padding: 0 12px;
    height: auto !important;
  }
}

.live-input {
  border: thin solid #edeef0;
  background: #f7f7f7;
  margin: 0 1em;
  border-radius: .2rem;
  margin-top: 20px;

  ::v-deep button > span, ::v-deep button > i {
    display: block;
  }

  .live-input-in {
    border-radius: 27px;
    position: relative;

    .live-input-textarea {
      width: calc(100% - 40px);
      border: none;
      background: transparent;
      // background: #fc0;
      margin: 0;
      resize: none;

      // padding: .5em 0 0 0;
      // max-height: 6em;

      padding: .4em 0 0 0;
      max-height: 3.2em;

      // font-size: .92rem;

      &[readonly] {
        color: #cdcdcd;
      }
    }

    .window-listing-sample-msgs {
      position: absolute;
      left: 1.2rem;
      bottom: .82rem;
      background: #fff;
      max-width: 320px;
      min-width: 100px;
      overflow: auto;
      overflow-x: hidden;
      max-height: 300px;
      display: none;

      &.show {
        display: block;
      }

      .notfound {
        font-size: 10pt;
      }
    }

    .list-group-item {
      padding: 0;
      font-size: 8pt;
      text-align: left;

      & > strong,
      & .sample-item,
      & > div > strong {
        padding: .35rem;
        display: block;
      }

      .sample-dones {
        position: relative;
        padding: .35rem;
        padding-top: 0;

        &::after {
          position: absolute;
          left: -5%;
          bottom: 0;
          content: "";
          height: 10px;
          width: 110%;
          box-shadow: 5px -5px 6px 0 rgba(255,255,255,0.5) inset;
        }
      }

      .sample-item {
        width: 92px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #c0c0c0;
      }
    }

    .filter-msgs {
      .input-group-prepend {
        & > .input-group-text {
          background: transparent;
        }
      }
    }

    .window-create-sample-msg {
      position: absolute;
      right: .32rem;
      bottom: .82rem;
      background: #fff;
      width: 420px;

      .window-create-sample-close {
        border: none;
        border-radius: 20px;
        background: #000;
        position: absolute;
        right: -15px;
        top: -15px;
        width: 32px;
        height: 32px;

        i {
          color: #fff;
        }
      }

      h1 {
        font-size: 12pt;
      }

      & > .relative {
        position: relative;
        padding: .7rem;
      }

      .qt-block-ui {
        position: absolute !important;
      }
    }

    .btn-create-sample-msg {
      background: transparent !important;
      border: none !important;
      height: auto !important;
      position: absolute;
      right: 0;
      top: 0;
      padding: .62rem .2rem;

      // i {
      //   color: #fff;
      // }

      & + textarea {
        width: calc(100% - 40px - 1.5rem);
      }
    }

    .sample-messages {
      height: 262px;
      overflow: auto;
      overflow-x: hidden;

      .list-group-item {
        text-align: left;

        & > div {
          max-height: 52px;
          overflow: hidden;
        }
      }
    }

    .new-sample {
      margin: 0 !important;

      * {
        font-size: 9pt !important;
      }

      textarea {
        min-height: 140px;
      }

      & > :first-child {
        padding: 0;
      }

      & > :last-child {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
      }

      .form-check-label {
        width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .form-check {
        & > input, & > label {
          vertical-align: middle;
        }

        & > label {
          margin-top: .1rem;
        }
      }

      .form-footer {
        & > :first-child {
          padding-right: .3rem !important;
        }

        & > :last-child {
          padding-left: .3rem !important;
        }
      }
    }
  }

  ::v-deep .emojis .popup-box-container button > span {
    display: block;
    height: 25px;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    width: 40px;
    height: 45px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}

/*
  .live-enter {
    float: right;
    height: 62px;
    width: 62px;
  }

  ::v-deep .emojis {
    float: left;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    float: left;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}*/

.live-inner-header {
  border-bottom-width: thin;
  position: relative;

  .material-icons-outlined {
    color: #752de6 !important;
  }

  .live-status {
    font-size: 7pt;
    font-weight: bold;
    vertical-align: middle;
  }

  .live-back {
    font-size: 14pt;
    float: left;
    width: 42px;
    height: 100%;
    background: transparent;
    border: none;

    &:hover {
      background: #fcfcfc;
    }

    @media (min-width: 801px) {
      display: none;
    }
  }

  & > .live-photo {
    margin: 18px 10px 0 10px;
    float: left;
    height: 48px;
    width: 48px;
    position: relative;
    border-radius: 0;
    background: transparent;

    .live-channel {
      position: absolute;
      right: -2px;
      top: -2px;
      font-size: 8pt;
      height: 16px;
      width: 16px;
      background: #fff;
      border-radius: 16px;
    }

    button {
      background: #dfdfdf;
      border: none;
      border-radius: 48px;
      float: left;
      height: 100%;
      width: 100%;
      font-size: 12pt;
      padding: 0;
      overflow: hidden;
      border-radius: 24px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .live-header-info {
    float: left;
    padding-top: 14px;
    width: calc(100% - 210px);
    font-size: 12pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  .live-header-name {
    font-weight: bold;

    .sub {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (max-width: 920px) {
    .live-header-info {
      width: calc(80% - 100px);
    }

    .live-header-tools-m {
      width: 32px;
      margin-right: 15px;

      ::v-deep button.dropdown-toggle {
        background: #fff !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }
      }
    }
  }
}

.live-history-header {
  text-align: right;
  padding: .5em;
  border: solid #f7d85c;
  border-width: thin 0;
  background: #ffefad;
  font-size: 9pt;

  button {
    background: rgba(0,0,0,.1);
    border-radius: 14px;
    border: none;
    padding: 2px 10px;
    margin-left: 6px;
    transition: background-color .3s ease;
    vertical-align: middle;

    &:hover {
      background: rgba(0,0,0,.3);
    }
  }
}

.live-header-tools {
  padding: 21px 5px 0 5px;
  float: right;
  width: 135px;
  text-align: right;

  @media (max-width: 920px) {
    display: none;
  }

  &::v-deep button, .menu-item {
    border: none !important;
    padding: 0 !important;
    border-radius: 50%;
    height: 36px !important;
    width: 36px !important;
    margin-left: 5px;
    vertical-align: middle;
  }

  .menu-item {
    display: inline-block;

    &::v-deep .dropdown {
      &, button {
        color: #788db4 !important;
        margin: 0 !important;
      }

      button {
        box-shadow: none !important;
        background: transparent !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }

        &:hover, &:active, &:focus {
          box-shadow: none !important;
          background: #fafafa !important;
        }
      }
    }
  }

  button::v-deep {
    box-shadow: none !important;
    background: transparent !important;

    &:hover, &:active, &:focus {
      box-shadow: none !important;
      background: #fafafa !important;
    }
  }
}

.live-header-tools-m {
  display: none;
  width: 20%;
  float: right;
  margin-top: 22px;
  text-align: center;

  @media (max-width: 920px) {
    display: block;
  }
}

.live-inner-input {
  border-top-width: thin;
}

.live-transcript {
  padding: 1.5em;
}

.live-message {
  font-size: 10pt;
  min-width: 240px;

  .live-agent {
    position: absolute;
    right: -10px;
    top: -10px;
    color: #fff;
    font-size: 7pt;
    text-transform: uppercase;
    padding: .5em !important;
    line-height: 1 !important;

    &.finished {
      background: #f00 !important;
    }
  }

  .live-message-box {
    min-width: 200px;
    max-width: 60%;
    float: left;

    .icon-fail {
      font-size: 8pt;
      color: #fff;
    }

    .live-message-source {
      padding: 4px 2px 20px 2px;
      font-size: 10pt;

      i {
        vertical-align: middle;
        margin-right: 2px;
      }
    }
  }

  .live-message-content {
    overflow-wrap: break-word;
    // word-break: break-all;
  }

  .live-message-content::v-deep {
    code {
      overflow-wrap: break-word;
      white-space: pre-wrap;
      color: inherit;
    }

    [data-interactive] {
      display: inline-block;
      position: relative;
      font-weight: 400;

      & > div {
        position: absolute;
        right: -45%;
        top: -82%;
        opacity: 0;
        transition: .2s all ease-out;

        & > button {
          box-shadow: 0 1px 1px 1px rgba(0,0,0,.1);
          border-radius: .1rem;
          border: thin solid #c1c1c1;
          background: #fff;
          color: #c1c1c1;
        }
      }

      &:hover > div {
        opacity: 1;
      }
    }
  }

  .live-message-inner {
    position: relative;
    border: 1px solid #d6dadd !important;
    background: #f5f5f5;
    padding: 14px 14px 32px 14px;
    border-radius: .24em;
    letter-spacing: normal !important;

    .live-message-opts {
      position: absolute;
      right: 10px;
      top: 10px;

      &::v-deep .dropdown-toggle {
        visibility: hidden !important;
        background: transparent !important;
        padding: .1em .4em;
        height: auto;
        line-height: 1;
      }
    }

    &:hover .live-message-opts {
      &::v-deep .dropdown-toggle {
        visibility: visible !important;
        background: transparent !important;
        padding: .1em .4em;
        height: auto;
        line-height: 1;
      }
    }

    & > picture {
      position: absolute;
      right: -10px;
      top: -10px;
      color: #fff;
      background: #cfcfcf;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 4px;
      pointer-events: none;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    & > strong {
      display: block;
      padding-bottom: .4em;
    }

    .live-message-details {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 80%;
    }
    .live-message-time {
      opacity: .7;
      margin-right: 5px;
    }
  }

  // &:not(.me) .live-message-inner {
  //   border-top-left-radius: 0;
  // }

  &.another-agent .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.place-offline .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.automatic .live-message-inner {
    background: #ffedc7 !important;
  }

  &.me .live-message-inner {
    background: #752de6 !important;
    border: none !important;
    color: #fff;
  }

  &.fail {
    .live-message-inner {
      background: var(--danger) !important;
      color: #fff;
    }
  }

  &.deleted {
    .live-message-content {
      color: #cfcfcf !important;

      .live-message-text {
        font-style: italic;
      }
    }
  }

  &.me .live-message-box, &.automatic .live-message-box {
    float: right;

    .live-message-source {
      text-align: right;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .live-message-attachments {
    video, img, audio {
      max-height: 320px;
      max-width: 100%;
    }
  }

  &.upload {
    .live-message-box {
      .live-message-inner {
        padding-bottom: 14px;
        white-space: nowrap;

        > span {
          margin-right: 4px;
        }
      }

      text-align: center;
      min-width: 1px;
      max-width: 60%;
    }
  }
}

.toggle-meta {
  @media (min-width: 1231px) {
    display: none;
  }
}

.live-input-count {
  position: absolute;
  right: 0;
  bottom: -17px;
  font-size: 7pt;

  .exceed {
    font-weight: bold;
    color: #f00;
  }
}

.live-input-btn {
  cursor: pointer;
  padding: 0;
  float: right;
  width: 40px;
  height: 45px;
  border: none;
  background: transparent;
  font-size: 12pt;
  transition: .3s background ease;
  outline: none;
  color: #752de6 !important;

  &:not(:enabled) {
    opacity: .5;
  }

  /*
  &:enabled, .live-speak {
    background: #752de6;
  }
  */

  span {
    color: inherit !important;
  }
}

/*
#live-attach {
  float: left;
}
*/

#live-attach-input {
  display: none;
}

.live-external {
  position: relative;
  background: rgba(0,0,0,.15);
  border-radius: 3px;
  display: block;
  color: #fff;
  overflow: hidden;

  & > i {
    color: inherit;
    text-align: center;
    vertical-align: middle;
    width: 62px;
    font-size: 42pt;
    padding: 8px;
    display: inline-block;
  }

  .live-attach-name {
    vertical-align: middle;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 70px);
    display: inline-block;
    text-align: right;
    direction: rtl;
  }

  .live-attach-type {
    background: rgba(0,0,0,.15);
    padding: 3px 6px;
    clear: both;
  }
}

#live-attach ::v-deep {
  button {
    border: none !important;
    background: transparent !important;
    width: 40px !important;
    padding: 0 !important;
    height: 45px !important;
    font-size: 14pt;
    box-shadow: none !important;

    &:hover, &:active {
      background: #fcfcfc !important;
    }
  }

  ul {
    top: auto !important;
    left: 0 !important;
    right: auto !important;
    bottom: 50px !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (max-width: 480px) {
    button {
      height: 58px !important;
      font-size: 14pt;
      padding: 0 15px;
    }

    ul {
      bottom: 38px !important;
    }
  }
}
.icon-live {
  font-size: 18pt;
  color: #c1c7cd;
  float: left;
  margin-top: 18px;
}
.fab-item-image{
  text-align: center !important;
}
.fab-item-image i{
  display: block;
  color: rgb(140, 140, 140);
  text-align: center;
  margin-top: 8px;
}

.fa-spinner {
  animation: rotation .32s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
