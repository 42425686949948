<template>
  <div class="dropzone dz-clickable">
    <div class="dz-message needsclick singleFileUpload">
      <span class="material-icons-outlined">image</span>
      <h5 class="card-title text-center p-t-15">Adicionar Fotos/Vídeos</h5>
      <input
        class="dropz-input"
        ref="uploadedImg"
        type="file"
        @change="handleImageUpload"
      />
    </div>
  </div>
</template>

<script>
import validationRules from '@/rules';

export default {
  name: 'DropZoneComponent',
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    value: {
      type: [String, Array, Object],
      default: '',
    },
    placeholder: {
      type: String,
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      allRules: [],
      image: null,
      tokens: {
        '#': { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        '!': { escape: true },
      },
      Deselect: {
        render: (createElement) => createElement('span', 'close'),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', 'expand_more'),
      },
    };
  },
  computed: {
    classObject() {
      return [
        this.variant,
      ];
    },
    isValid() {
      return this.errors.length === 0;
    },
  },
  created() {
    this.allRules = this.rules;
    if (this.required) {
      this.allRules.push('required');
    }
    if (this.type === 'email') {
      this.allRules.push('email');
    }
    if (this.type === 'tel') {
      this.allRules.push('tel');
    }
  },
  methods: {
    handleImageUpload(event) {
      // console.log('Debug:: ', this.image);
      this.$emit('upload', event);
    },
    handleClick(e) {
      this.$emit('click', e);
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    handleBlur(event) {
      this.validate(event.target.value);
      this.$emit('input', event.target.value);
    },
    handleChange(event) {
      this.validate(event.target.value);
      this.$emit('input', event.target.value);
    },
    validate(value) {
      if (this.allRules.length) {
        this.errors = [];
        this.allRules.forEach((rule) => {
          rule = rule.split(':');
          if (validationRules[rule[0]]) {
            if (!validationRules[rule[0]].condition(value, rule[1])) {
              const message = validationRules[rule[0]].getMessage();

              if (message) {
                this.errors.push(this.$t(message, [rule[1]]));
              } else {
                this.errors.push('');
              }
            }
          }
        });
        // Validate mask
        if (this.mask && !this.errors.length) {
          if (value.length !== this.mask.length) {
            const message = validationRules.mask.getMessage();
            this.errors.push(this.$t(message));
          }
        }
      }
    },
    checkValidation() {
      this.validate(this.value);
    },
    maskIt(value) {
      if (this.mask) {
        value = value || '';
        let iMask = 0;
        let iValue = 0;
        let output = '';
        while (iMask < this.mask.length && iValue < value.length) {
          let cMask = this.mask[iMask];
          const masker = this.tokens[cMask];
          const cValue = value[iValue];
          if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
              output += masker.transform ? masker.transform(cValue) : cValue;
              iMask += 1;
            }
            iValue += 1;
          } else {
            if (masker && masker.escape) {
              iMask += 1; // take the next mask char and treat it as char
              cMask = this.mask[iMask];
            }
            output += cMask;
            if (cValue === cMask) iValue += 1; // user typed the same char
            iMask += 1;
          }
        }
        return output;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  position: relative;
  border: 2px dashed #6F6F6F;

  .dz-message {
    padding: 97px 51px;
    margin: 0;

    .material-icons-outlined {
      font-size: 43px;
      color: #2494FF;
    }
  }
  .dropz-input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
