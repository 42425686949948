<template>
  <span>
    <ButtonComponent variant="secondary" @click="edit">
      <template v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </template>
      <template v-if="editing">
        <input :value="value" ref="input" type="text" @input="handleInput"/>
      </template>
      <template v-else>
      <slot></slot>
      </template>
    </ButtonComponent>
  </span>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'ButtonInputComponent',
  components: {
    ButtonComponent,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    classObject() {
      return [
        this.variant,
        { 'qt-loader qt-loader-mini qt-loader-right': this.loading },
      ];
    },
    isValid() {
      return this.errors.length === 0;
    },
  },
  created() {
    this.allRules = this.rules;
    if (this.required) {
      this.allRules.push('required');
    }
    if (this.type === 'email') {
      this.allRules.push('email');
    }
    if (this.type === 'tel') {
      this.allRules.push('tel');
    }
  },
  methods: {
    edit() {
      this.editing = true;
      this.$refs.input.focus();
    },
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: none;
}
</style>
