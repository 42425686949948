<template>
  <div class="p-3 main">
    <div class="sr-only">
      <pre>{{numbers}}</pre>
      <pre>recipients: {{recipients}}</pre>
      <pre>{{template}}</pre>
    </div>
    <div class="qt-loader" v-if="uploading || sending"></div>

    <template>
      <div class="row mt-3">
        <div class="col-12 col-lg-8">
          <aside class="preview p-4 mb-2" v-if="template">
            <div class="preview-sample">
              <div class="preview-message">
                <template v-if="headerType">
                  <header v-if="headerType === 'TEXT'">
                    {{headerPreview}}
                  </header>
                  <header v-else-if="headerType === 'IMAGE'">
                    <div class="media">
                      <img
                        :src="headerCurrentImage"
                        v-if="headerCurrentImage"/>
                    </div>
                  </header>
                  <header v-else-if="headerType === 'VIDEO'">
                    <div class="media">
                      <video
                        :src="headerCurrentVideo"
                        v-if="headerCurrentVideo"
                        autoplay></video>
                    </div>
                  </header>
                  <header v-else-if="headerType === 'DOCUMENT'">
                    <div class="media document">
                      <!-- <object
                        type="application/pdf"
                        :data="headerCurrentDocument"
                        v-if="headerCurrentDocument"></object> -->
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAAD3d3fxRkbyRkbyRkb3fn74g4PyRUX3lZXzRUXyRUX/RkbyQED3fn7zRUX2iYn/Rkb4lpb0cHDvQEDzRETzRkbzQEDzQkLzOzv////939/yODj2enr4kJD0UVHzPT38xMT92tr5lZX1ZGT+6+v4g4P1bW35m5v7yMj91dX3fn76sLD1XFz6qan//f36trb+5OT0VVX6rKz7vb3yNDT4iIj/9vb0TEz5o6PtTEzlRUXsYWHiNzfyRETsSEiKDgROAAAAFHRSTlMAH+Z5+80n+JHu0AWTv6w6CX3V/BbNCjoAAAHfSURBVHgB7daDsisxGMDxHNtftLZqu+//WDebOu1p08H1f7D8zRoIoferx4djXb4htYvXy7vrI32NXi4U8v6KjzcbFTcKer48bQplS3dYwyhbwhpGRTpGRZpGoNvzjUT6RkU6RkX6RiJNM7dUhE/2NYkVhDU2lCgIa6DxxNr0eathBPoab5re6xjJ1pkaRu2XmP8GiIzKCSrHAW9NiKhiwKnKsFkukRMOZaWCVnWZA7uGxEaZn6UDApBGYjz3ekDFZtrGoig1FRPwZXnoQoXL6p0qwSTjy9pdxdicG41K1ue8g6kwRiWLOPcHQDzO84aoE7N9M2SMWX1eD11hal0zNMQ8tzQ2c0UU75saBXB9zhMmTJthsyc2J7cTuIRSQQ5sxzRZK9oYN4x4VGXCDMtz14IDxiusntiM0SILQ6oG71umMLKcHTCL+rEL+qZe5iWUwta+hUwYz7btoCAHTCdJEgcTgKUxY84NfPwc1AihABhLM+x2N+c6oICXqWa5RJo8bTQ59x04YgJhYMvIMnnvfGtivxmsTdr0RbmNCca0ZjR7FCtGNnCcAd6aEIELyhLFABXb35qg62k59v9d9d/8MnN5fa75QFeXZ5qvN4SezzTPCKEn8et/p9vD49UT+gEdNgrQ89qG8QAAAABJRU5ErkJggg==" alt="" />
                    </div>
                  </header>
                </template>
                <div>
                  {{bodyPreview}}
                </div>
                <footer>
                  {{footerBody}} &nbsp;
                </footer>
                <time>17:50</time>
              </div>
              <div class="preview-btns" v-if="template.buttons">
                <template v-if="template.buttons.type === 'QUICK_REPLY' && template.buttons.quick_replies">
                  <template v-for="(reply, i) in template.buttons.quick_replies.filter((a) => !!a)">
                    <div class="btn" :key="i" :class="{ last: i === 2 }">{{reply}}</div>
                  </template>
                </template>
                <template v-if="template.buttons.type === 'CALL_TO_ACTION'">
                  <div
                    class="btn last"
                    v-if="template.buttons.call_to_action_dial_phone">
                    <i class="fas fa-phone"></i>
                    {{template.buttons.call_to_action_dial_phone.text}}
                  </div>
                  <div
                    class="btn last"
                    v-if="template.buttons.call_to_action_website">
                    <i class="fas fa-link"></i>
                    {{template.buttons.call_to_action_website.text}}
                  </div>
                </template>
              </div>
            </div>
          </aside>
          <!-- <h3 v-if="headerType === 'TEXT'">{{template.header.body}}</h3>
          <template v-else-if="headerType === 'VIDEO'">
            <video accept="video/*" v-if="true"></video>
          </template>
          <template v-else-if="headerType === 'DOCUMENT'">
            <object data="meuarquivo.pdf" type="application/pdf" v-if="true">
              <p>Seu navegador não tem um plugin pra PDF</p>
            </object>
          </template>
          <template v-else-if="headerType === 'IMAGE'">
            <img src="" v-if="true" />
          </template> -->
        </div>
        <div class="col-12 col-lg-4">
          <div class="card card-border-info">
            <div class="card-header p-l-20 p-b-15 p-t-15">Opções</div>
            <div class="card-body">
              <template v-if="extras">
                <div class="custom-control custom-checkbox custom-control-inline custom-control-inline-send mb-3">
                  <input
                    class="tgl tgl-light tgl-primary calendar-change"
                    id="scheduleWA"
                    type="checkbox"
                    v-model="scheduled"
                    name="schedule"
                    @change="() => {}"
                  />
                  <label class="tgl-btn" for="scheduleWA"></label>
                  <label class="label-send-check">{{$t('options-div.lbl-schedule')}}</label> <!-- Agendar -->
                </div>
                <div class="mb-3">
                  <date-picker
                    v-if="scheduled"
                    v-model="scheduledFor"
                    :config="options"
                    class="form-control input-date"
                    :placeholder="$t('generic-str.date-hour')" />
                </div>
              </template>
              <div class="mb-3">
                <select
                  :disabled="!numbers || numbers.length < 2"
                  class="form-control"
                  aria-label="Default select example"
                  v-model="currentNumberIndex"
                  @change="fetchTemplates()">
                  <template v-for="(item, i) of numbers">
                    <option :key="i" :value="i">
                      {{item.phone_number}} ({{item.name}})
                    </option>
                  </template>
                </select>
              </div>
              <div class="mb-3">
                <label for="template">Template</label>
                <select
                  id="template"
                  class="form-control"
                  aria-label="Default select example"
                  v-model="currentTemplateIndex"
                  @change="changeTemplate">
                  <option value="">Selecione o template...</option>
                  <template v-for="(item, i) of templates">
                    <option :key="`whatsappsendform:${i}`" :value="i">
                      {{item.name}} ({{item.language}})
                    </option>
                  </template>
                </select>
              </div>
              <div class="my-3" v-if="headerType">
                <div v-if="headerType === 'TEXT' && headerBody.indexOf('{{1}}') !== -1">
                  <label for="headerParam">Paremetro do header</label>
                  <input
                    id="headerParam"
                    class="form-control"
                    type="text"
                    v-model="headerParam">
                </div>
                <input
                  type="file"
                  accept="video/*"
                  class="form-control"
                  :class="{ 'd-none': headerType !== 'VIDEO' }"
                  @change="selectFile">
                <input
                  type="file"
                  accept="application/pdf"
                  class="form-control"
                  :class="{ 'd-none': headerType !== 'DOCUMENT' }"
                  @change="selectFile">
                <!-- <input
                  type="file"
                  accept="image/jpeg,image/png"
                  class="form-control"
                  :class="{ 'd-none': headerType !== 'IMAGE' }"
                  @change="selectFile"> -->
                <div v-if="headerType === 'IMAGE'">
                  <button
                    class="btn btn-secondary"
                    @click="chooseImage(500, 280)">
                    Escolher JPG ou PNG
                  </button>
                </div>
              </div>
              <div class="my-3" v-if="templateBodyParams && templateBodyParams.length">
                <h3>Corpo</h3>
                <template v-for="(example, i) in templateBodyParams">
                  <div class="my-2" :key="`body_example_${i}`">
                    <label :for="`body_example_${i}`">Parametro {{i + 1}}</label>
                    <input
                      :id="`body_example_${i}`"
                      class="form-control"
                      type="text"
                      :placeholder="example"
                      v-model="bodyParams[i]">
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4">
      <div class="d-flex flex-row-reverse">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!validForm"
          @click="sendMessage()">Enviar</button>
      </div>
    </template>
    <div class="alert alert-danger" role="alert" v-if="error">
      <div class="d-flex">
        <div class="p-2">Templates não foram carregados</div>
        <div class="ml-auto p-2">
          <button class="btn btn-primary" @click="fetchNumbers()">Atualizar</button>
        </div>
      </div>
    </div>
    <m-crop id="m-crop" />
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/plugins/axios';
import datePicker from 'vue-bootstrap-datetimepicker';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import WhatsAppService from '@/services/whatsapp.service';

export default {
  name: 'WhatsappSendForm',
  components: {
    datePicker,
    MCrop,
  },
  props: ['recipients', 'extras', 'clientRoomId'],
  computed: {
    template() {
      if (this.currentTemplateIndex === -1 || this.currentTemplateIndex === '') return null;

      return this.templates[this.currentTemplateIndex];
    },
    headerType() {
      return this.template?.header?.type || null;
    },
    headerBody() {
      return this.template?.header?.body || '';
    },
    customHeader() {
      const header = this.template?.header;
      return header?.type && (header?.type !== 'TEXT' || header.body?.indexOf('{{1}}') !== -1);
    },
    templateBodyParams() {
      const body = this.template?.body;

      return body && [...body?.text?.matchAll(/\{\{\d+}\}/g)].map((param, index) => {
        if (body.example_parameters[index]) return body.example_parameters[index];

        return `Parametro {{${index + 1}}}`;
      });
    },
    customizable() {
      return this.customHeader || this.templateBodyParams?.length > 0;
    },
    headerCurrentVideo() {
      if (this.headerType === 'VIDEO') {
        return this.headerVideo || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerCurrentDocument() {
      if (this.headerType === 'DOCUMENT') {
        return this.headerDocument || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerCurrentImage() {
      if (this.headerType === 'IMAGE') {
        return this.headerImage || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerPreview() {
      return this.template?.header?.body?.replace(/\{\{1\}\}/, (param) => this.headerParam || param);
    },
    bodyPreview() {
      return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] || param;
      });
    },
    headerResults() {
      const result = this.template?.header?.body;

      return !result ? '' : result.replace(/\{\{1\}\}/, (param) => this.headerParam || '');
    },
    bodyResults() {
      return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] ?? null;
      });
    },
    footerBody() {
      return this.template?.footer?.body;
    },
    validForm() {
      console.log('validForm', {
        headerType: this.headerType,
        'this.bodyResults?.indexOf(null)': this.bodyResults?.indexOf(null),
      });
      return this.bodyResults?.indexOf(null) === -1 && (
        !this.headerType ||
        (this.headerType === 'TEXT' && this.headerPreview.indexOf('{{1}}}') === -1) ||
        (this.headerType === 'IMAGE' && this.headerCurrentImage) ||
        (this.headerType === 'VIDEO' && this.headerCurrentVideo) ||
        (this.headerType === 'DOCUMENT' && this.headerCurrentDocument)
      );
    },
  },
  data() {
    return {
      error: false,
      number: 0,
      numbers: [],
      templates: [],
      businessId: null,
      currentNumberIndex: 0,
      currentTemplateIndex: -1,

      scheduled: false,
      scheduledFor: '',
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },

      foo: [
        {
          id: 1,
          name: 'Yup Chat',
          token: 'EAAIHdrDSTM0BAMZCnHYW3h8XcXedd3BMkn7RYk4eIKuSoW3QuIz8ll8B0dLB1yeVLebdTBMHbgyJ2IZCZCXr67CWMt22geQvZCZA2f44W7SQMVyzZCfh3fAxHaUwsaFyrFZBGZCjbEhehu8C6vmjAimrrMeZBLpHAkmBFg8HJejbHZCwZDZD',
          deleted: false,
          creation_date: '2022-10-26T15:27:08.463192',
          account_id: '52867698-ac7b-4186-a7e9-8525161c8b7f',
          business_identification: '644721329205903',
          whatsapp_identification: '105694852190266',
          phone_identification: '108927061859720',
          phone_number: '558006911000',
          webhook_business_add: 'http://app.yup.chat:8097/webhook/whatsapp-java',
          webhook_business_update: 'http://app.yup.chat:8097/update/whatsapp-java',
        },
      ],
      bar: {
        content: [
          {
            id: '91',
            status: 'APPROVED',
            identification: '674073007750555',
            deleted: false,
            name: 'teste',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '68',
              type: 'TEXT',
              body: 'teste',
            },
            body: {
              id: '91',
              text: 'teste',
              example_parameters: [],
            },
            footer: {
              id: '79',
              body: 'teste',
            },
            creation_date: '2022-11-25T03:11:32.889',
            last_update: '2022-12-21T02:23:02.069',
            business_id: '105694852190266',
          },
          {
            id: '34',
            status: 'APPROVED',
            identification: '684117183143284',
            deleted: false,
            name: 'template_ola_body_teste',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            body: {
              id: '34',
              text: 'Boa tarde, {{1}}! Seu atendimento será iniciado por {{2}}.',
              example_parameters: [
                'Alex',
                'Maria',
              ],
            },
            creation_date: '2022-11-24T15:11:31.844',
            last_update: '2022-12-21T02:23:02.072',
            business_id: '105694852190266',
          },
          {
            id: '36',
            status: 'APPROVED',
            identification: '4047678695355993',
            deleted: false,
            name: 'erico_teste',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            body: {
              id: '36',
              text: 'Olá, tudo bem?',
              example_parameters: [],
            },
            creation_date: '2022-11-24T15:11:31.856',
            last_update: '2022-12-21T02:23:02.074',
            business_id: '105694852190266',
          },
          {
            id: '37',
            status: 'APPROVED',
            identification: '623142489556215',
            deleted: false,
            name: 'exemplo',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            body: {
              id: '37',
              text: 'teste',
              example_parameters: [],
            },
            creation_date: '2022-11-24T15:11:31.86',
            last_update: '2022-12-21T02:23:02.076',
            business_id: '105694852190266',
          },
          {
            id: '42',
            status: 'APPROVED',
            identification: '441574344774365',
            deleted: false,
            name: 'gui',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '29',
              type: 'TEXT',
              body: 'header',
            },
            body: {
              id: '42',
              text: 'body',
              example_parameters: [],
            },
            footer: {
              id: '35',
              body: 'footer',
            },
            creation_date: '2022-11-24T15:11:31.888',
            last_update: '2022-12-21T02:23:02.078',
            business_id: '105694852190266',
          },
          {
            id: '43',
            status: 'APPROVED',
            identification: '1268209477277740',
            deleted: false,
            name: 'string',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '30',
              type: 'TEXT',
              body: 'string',
            },
            body: {
              id: '43',
              text: 'string',
              example_parameters: [],
            },
            footer: {
              id: '36',
              body: 'string',
            },
            creation_date: '2022-11-24T15:11:31.892',
            last_update: '2022-12-21T02:23:02.08',
            business_id: '105694852190266',
          },
          {
            id: '61',
            status: 'APPROVED',
            identification: '4610507155718599',
            deleted: false,
            name: 'sample_happy_hour_announcement',
            language: 'en_US',
            category: 'APPOINTMENT_UPDATE',
            header: {
              id: '47',
              type: 'VIDEO',
            },
            body: {
              id: '61',
              text: 'Happy hour is here! 🍺😀🍸\nPlease be merry and enjoy the day. 🎉\nVenue: {{1}}\nTime: {{2}}',
              example_parameters: [],
            },
            footer: {
              id: '50',
              body: 'This message is from an unverified business.',
            },
            creation_date: '2022-11-24T15:11:32.001',
            last_update: '2022-12-21T02:23:02.12',
            business_id: '105694852190266',
          },
          {
            id: '62',
            status: 'APPROVED',
            identification: '1168720183984724',
            deleted: false,
            name: 'sample_happy_hour_announcement',
            language: 'pt_BR',
            category: 'APPOINTMENT_UPDATE',
            header: {
              id: '48',
              type: 'VIDEO',
            },
            body: {
              id: '62',
              text: 'O happy hour chegou! 🍺😀🍸\nSeja feliz e aproveite o dia. 🎉\nLocal: {{1}}\nHorário: {{2}}',
              example_parameters: [],
            },
            footer: {
              id: '51',
              body: 'Esta mensagem é de uma empresa não verificada.',
            },
            creation_date: '2022-11-24T15:11:32.007',
            last_update: '2022-12-21T02:23:02.123',
            business_id: '105694852190266',
          },
          {
            id: '63',
            status: 'APPROVED',
            identification: '741175846923877',
            deleted: false,
            name: 'sample_happy_hour_announcement',
            language: 'es',
            category: 'APPOINTMENT_UPDATE',
            header: {
              id: '49',
              type: 'VIDEO',
            },
            body: {
              id: '63',
              text: '¡Llegó el happy hour! 🍺😀🍸\nA divertirse y disfrutar. 🎉\nLugar: {{1}}\nHora: {{2}}',
              example_parameters: [],
            },
            footer: {
              id: '52',
              body: 'Este mensaje proviene de un negocio no verificado.',
            },
            creation_date: '2022-11-24T15:11:32.013',
            last_update: '2022-12-21T02:23:02.125',
            business_id: '105694852190266',
          },
          {
            id: '64',
            status: 'APPROVED',
            identification: '588102276264727',
            deleted: false,
            name: 'sample_happy_hour_announcement',
            language: 'id',
            category: 'APPOINTMENT_UPDATE',
            header: {
              id: '50',
              type: 'VIDEO',
            },
            body: {
              id: '64',
              text: 'Jam diskon telah tiba! 🍺😀🍸\nBergembiralah dan nikmati hari Anda. 🎉\nTempat: {{1}}\nWaktu: {{2}}',
              example_parameters: [],
            },
            footer: {
              id: '53',
              body: 'Pesan ini berasal dari bisnis yang tidak terverifikasi.',
            },
            creation_date: '2022-11-24T15:11:32.018',
            last_update: '2022-12-21T02:23:02.128',
            business_id: '105694852190266',
          },
          {
            id: '45',
            status: 'APPROVED',
            identification: '1227122324531255',
            deleted: false,
            name: 'template_teste_log_2',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '31',
              type: 'TEXT',
              body: 'Header log 2',
            },
            body: {
              id: '45',
              text: 'Body log 2',
              example_parameters: [],
            },
            footer: {
              id: '37',
              body: 'Footer log 2',
            },
            buttons: {
              id: '17',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {
                text: 'Telefone',
                number: '+5583983553898',
              },
              call_to_action_website: {},
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.902',
            last_update: '2022-12-21T02:23:02.082',
            business_id: '105694852190266',
          },
          {
            id: '46',
            status: 'APPROVED',
            identification: '842799707078251',
            deleted: false,
            name: 'template_log_2',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '32',
              type: 'TEXT',
              body: 'Header teste',
            },
            body: {
              id: '46',
              text: 'Body teste',
              example_parameters: [],
            },
            footer: {
              id: '38',
              body: 'Footer teste',
            },
            buttons: {
              id: '18',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {
                text: 'Telefone',
                number: '+5583983553898',
              },
              call_to_action_website: {},
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.911',
            last_update: '2022-12-21T02:23:02.084',
            business_id: '105694852190266',
          },
          {
            id: '47',
            status: 'APPROVED',
            identification: '418276057170866',
            deleted: false,
            name: 'template_teste_log',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '33',
              type: 'TEXT',
              body: 'Header log',
            },
            body: {
              id: '47',
              text: 'Body log',
              example_parameters: [],
            },
            footer: {
              id: '39',
              body: 'Footer log',
            },
            buttons: {
              id: '19',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {
                text: 'Telefone',
                number: '+5583983553898',
              },
              call_to_action_website: {},
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.918',
            last_update: '2022-12-21T02:23:02.087',
            business_id: '105694852190266',
          },
          {
            id: '48',
            status: 'APPROVED',
            identification: '5986198824745830',
            deleted: false,
            name: 'um_template_de_teste3343434',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '34',
              type: 'TEXT',
              body: 'Um header',
            },
            body: {
              id: '48',
              text: 'Um body',
              example_parameters: [],
            },
            footer: {
              id: '40',
              body: 'Um footer',
            },
            buttons: {
              id: '20',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {
                text: 'Telefone',
                number: '+5583983553898',
              },
              call_to_action_website: {},
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.923',
            last_update: '2022-12-21T02:23:02.089',
            business_id: '105694852190266',
          },
          {
            id: '49',
            status: 'APPROVED',
            identification: '501368715212372',
            deleted: false,
            name: 'um_template_de_teste',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '35',
              type: 'TEXT',
              body: 'Um template de teste',
            },
            body: {
              id: '49',
              text: 'Um body de teste',
              example_parameters: [],
            },
            footer: {
              id: '41',
              body: 'Um footer de teste',
            },
            buttons: {
              id: '21',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {
                text: 'Telefone',
                number: '+5583983553898',
              },
              call_to_action_website: {},
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.93',
            last_update: '2022-12-21T02:23:02.091',
            business_id: '105694852190266',
          },
          {
            id: '50',
            status: 'APPROVED',
            identification: '782491569686895',
            deleted: false,
            name: 'template_teste_2',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '36',
              type: 'IMAGE',
            },
            body: {
              id: '50',
              text: 'Template com Header (Image) e CTAs',
              example_parameters: [],
            },
            buttons: {
              id: '22',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {
                text: 'Ligar...',
                number: '+5511993609755',
              },
              call_to_action_website: {
                text: 'Website',
                url: 'https://yup.chat/',
              },
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.939',
            last_update: '2022-12-21T02:23:02.093',
            business_id: '105694852190266',
          },
          {
            id: '51',
            status: 'APPROVED',
            identification: '1154666718420624',
            deleted: false,
            name: 'template_teste_1',
            language: 'pt_BR',
            category: 'MARKETING',
            header: {
              id: '37',
              type: 'TEXT',
              body: 'Header: {{1}}',
            },
            body: {
              id: '51',
              text: 'Body 1 = {{1}}\nBody 2 = {{2}}\nTeste',
              example_parameters: [],
            },
            buttons: {
              id: '23',
              type: 'QUICK_REPLY',
              call_to_action_dial_phone: {},
              call_to_action_website: {},
              quick_replies: [
                'Botao 1',
                'Botao 2',
                'Botao 3',
              ],
            },
            creation_date: '2022-11-24T15:11:31.944',
            last_update: '2022-12-21T02:23:02.095',
            business_id: '105694852190266',
          },
          {
            id: '52',
            status: 'APPROVED',
            identification: '1121963188748382',
            deleted: false,
            name: 'template_ola',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '38',
              type: 'TEXT',
              body: '{{1}}',
              example_parameter: 'Bom Dia',
            },
            body: {
              id: '52',
              text: 'Olá, {{1}}! O atendimento será iniciado em breve!',
              example_parameters: [
                'Anthony',
              ],
            },
            creation_date: '2022-11-24T15:11:31.949',
            last_update: '2022-12-21T02:23:02.098',
            business_id: '105694852190266',
          },
          {
            id: '55',
            status: 'APPROVED',
            identification: '1087076738545294',
            deleted: false,
            name: 'sample_flight_confirmation',
            language: 'pt_BR',
            category: 'TICKET_UPDATE',
            header: {
              id: '41',
              type: 'DOCUMENT',
            },
            body: {
              id: '55',
              text: 'Esta é a sua confirmação de voo para {{1}}-{{2}} em {{3}}.',
              example_parameters: [],
            },
            footer: {
              id: '44',
              body: 'Esta mensagem é de uma empresa não verificada.',
            },
            creation_date: '2022-11-24T15:11:31.965',
            last_update: '2022-12-21T02:23:02.105',
            business_id: '105694852190266',
          },
          {
            id: '60',
            status: 'APPROVED',
            identification: '786832625640706',
            deleted: false,
            name: 'sample_purchase_feedback',
            language: 'id',
            category: 'ISSUE_RESOLUTION',
            header: {
              id: '46',
              type: 'IMAGE',
            },
            body: {
              id: '60',
              text: 'Terima kasih sudah membeli {{1}}! Kami menghargai masukan Anda dan ingin mempelajari lebih lanjut terkait pengalaman Anda.',
              example_parameters: [],
            },
            footer: {
              id: '49',
              body: 'Pesan ini berasal dari bisnis yang tidak terverifikasi.',
            },
            buttons: {
              id: '27',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {},
              call_to_action_website: {
                text: 'Ikuti survei',
                url: 'https://www.example.com/',
              },
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.996',
            last_update: '2022-12-21T02:23:02.118',
            business_id: '105694852190266',
          },
          {
            id: '118',
            status: 'APPROVED',
            identification: '866470984370075',
            deleted: false,
            name: 'hthoixyenq0r3rrs7yje',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '84',
              type: 'TEXT',
              body: 'header',
            },
            body: {
              id: '118',
              text: 'gbIcWIzJoDzSKhbMg2Kf',
              example_parameters: [],
            },
            footer: {
              id: '92',
              body: 'footer',
            },
            creation_date: '2022-11-29T05:49:00.591',
            last_update: '2022-12-21T02:23:02.065',
            business_id: '105694852190266',
          },
          {
            id: '53',
            status: 'APPROVED',
            identification: '5179014765485000',
            deleted: false,
            name: 'sample_flight_confirmation',
            language: 'id',
            category: 'TICKET_UPDATE',
            header: {
              id: '39',
              type: 'DOCUMENT',
            },
            body: {
              id: '53',
              text: 'Ini merupakan konfirmasi penerbangan Anda untuk {{1}}-{{2}} di {{3}}.',
              example_parameters: [],
            },
            footer: {
              id: '42',
              body: 'Pesan ini berasal dari bisnis yang tidak terverifikasi.',
            },
            creation_date: '2022-11-24T15:11:31.954',
            last_update: '2022-12-21T02:23:02.1',
            business_id: '105694852190266',
          },
          {
            id: '54',
            status: 'APPROVED',
            identification: '1540296289699500',
            deleted: false,
            name: 'sample_flight_confirmation',
            language: 'es',
            category: 'TICKET_UPDATE',
            header: {
              id: '40',
              type: 'DOCUMENT',
            },
            body: {
              id: '54',
              text: 'Confirmamos tu vuelo a {{1}}-{{2}} para el {{3}}.',
              example_parameters: [],
            },
            footer: {
              id: '43',
              body: 'Este mensaje proviene de un negocio no verificado.',
            },
            creation_date: '2022-11-24T15:11:31.959',
            last_update: '2022-12-21T02:23:02.102',
            business_id: '105694852190266',
          },
          {
            id: '56',
            status: 'APPROVED',
            identification: '392228542931155',
            deleted: false,
            name: 'sample_flight_confirmation',
            language: 'en_US',
            category: 'TICKET_UPDATE',
            header: {
              id: '42',
              type: 'DOCUMENT',
            },
            body: {
              id: '56',
              text: 'This is your flight confirmation for {{1}}-{{2}} on {{3}}.',
              example_parameters: [],
            },
            footer: {
              id: '45',
              body: 'This message is from an unverified business.',
            },
            creation_date: '2022-11-24T15:11:31.972',
            last_update: '2022-12-21T02:23:02.108',
            business_id: '105694852190266',
          },
          {
            id: '57',
            status: 'APPROVED',
            identification: '4980056215449545',
            deleted: false,
            name: 'sample_purchase_feedback',
            language: 'en_US',
            category: 'ISSUE_RESOLUTION',
            header: {
              id: '43',
              type: 'IMAGE',
            },
            body: {
              id: '57',
              text: 'Thank you for purchasing {{1}}! We value your feedback and would like to learn more about your experience.',
              example_parameters: [],
            },
            footer: {
              id: '46',
              body: 'This message is from an unverified business.',
            },
            buttons: {
              id: '24',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {},
              call_to_action_website: {
                text: 'Take Survey',
                url: 'https://www.example.com/',
              },
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.978',
            last_update: '2022-12-21T02:23:02.11',
            business_id: '105694852190266',
          },
          {
            id: '58',
            status: 'APPROVED',
            identification: '1031705504139034',
            deleted: false,
            name: 'sample_purchase_feedback',
            language: 'es',
            category: 'ISSUE_RESOLUTION',
            header: {
              id: '44',
              type: 'IMAGE',
            },
            body: {
              id: '58',
              text: '¡Gracias por comprar {{1}}! Valoramos tus comentarios y nos gustaría saber cómo fue tu experiencia.',
              example_parameters: [],
            },
            footer: {
              id: '47',
              body: 'Este mensaje proviene de un negocio no verificado.',
            },
            buttons: {
              id: '25',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {},
              call_to_action_website: {
                text: 'Responder encuesta',
                url: 'https://www.example.com/',
              },
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.985',
            last_update: '2022-12-21T02:23:02.113',
            business_id: '105694852190266',
          },
          {
            id: '59',
            status: 'APPROVED',
            identification: '1022243005321602',
            deleted: false,
            name: 'sample_purchase_feedback',
            language: 'pt_BR',
            category: 'ISSUE_RESOLUTION',
            header: {
              id: '45',
              type: 'IMAGE',
            },
            body: {
              id: '59',
              text: 'Agradecemos a aquisição de {{1}}! Valorizamos seu feedback e gostaríamos de saber mais sobre sua experiência.',
              example_parameters: [],
            },
            footer: {
              id: '48',
              body: 'Esta mensagem é de uma empresa não verificada.',
            },
            buttons: {
              id: '26',
              type: 'CALL_TO_ACTION',
              call_to_action_dial_phone: {},
              call_to_action_website: {
                text: 'Participe da pesquisa',
                url: 'https://www.example.com/',
              },
              quick_replies: [],
            },
            creation_date: '2022-11-24T15:11:31.991',
            last_update: '2022-12-21T02:23:02.115',
            business_id: '105694852190266',
          },
          {
            id: '111',
            status: 'APPROVED',
            identification: '509430341218326',
            deleted: false,
            name: 'hhs38fm4meusxrwl0mch',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '77',
              type: 'TEXT',
              body: 'header',
            },
            body: {
              id: '111',
              text: 'EwXKpqY9QUYkmabvgy1D',
              example_parameters: [],
            },
            footer: {
              id: '85',
              body: 'footer',
            },
            creation_date: '2022-11-29T05:49:00.568',
            last_update: '2022-12-21T02:23:02.057',
            business_id: '105694852190266',
          },
          {
            id: '113',
            status: 'APPROVED',
            identification: '1135396380433635',
            deleted: false,
            name: 'az17sdomxekhavyyzbfg',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '79',
              type: 'TEXT',
              body: 'header',
            },
            body: {
              id: '113',
              text: '9uzHBb5PUhXD6CmaaiqV',
              example_parameters: [],
            },
            footer: {
              id: '87',
              body: 'footer',
            },
            creation_date: '2022-11-29T05:49:00.574',
            last_update: '2022-12-21T02:23:02.059',
            business_id: '105694852190266',
          },
          {
            id: '115',
            status: 'APPROVED',
            identification: '3454841628095701',
            deleted: false,
            name: 'dzucg2qwiepdtbro9dly',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '81',
              type: 'TEXT',
              body: 'header',
            },
            body: {
              id: '115',
              text: 'ZkJrvMoRGTAAOXuPRU57',
              example_parameters: [],
            },
            footer: {
              id: '89',
              body: 'footer',
            },
            creation_date: '2022-11-29T05:49:00.582',
            last_update: '2022-12-21T02:23:02.061',
            business_id: '105694852190266',
          },
          {
            id: '117',
            status: 'APPROVED',
            identification: '832847771321573',
            deleted: false,
            name: 'urtnnqdhh9g4z4m09edr',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '83',
              type: 'TEXT',
              body: 'header',
            },
            body: {
              id: '117',
              text: 'OGghajKFngos5ptzcvCn',
              example_parameters: [],
            },
            footer: {
              id: '91',
              body: 'footer',
            },
            creation_date: '2022-11-29T05:49:00.588',
            last_update: '2022-12-21T02:23:02.063',
            business_id: '105694852190266',
          },
          {
            id: '155',
            status: 'APPROVED',
            identification: '3168647603394790',
            deleted: false,
            name: 'sample_shipping_confirmation',
            language: 'id',
            category: 'SHIPPING_UPDATE',
            body: {
              id: '155',
              text: 'Paket Anda sudah dikirim. Paket akan sampai dalam {{1}} hari kerja.',
              example_parameters: [],
            },
            footer: {
              id: '113',
              body: 'Pesan ini berasal dari bisnis yang tidak terverifikasi.',
            },
            creation_date: '2022-12-19T20:27:13.026',
            last_update: '2022-12-21T02:23:02.13',
            business_id: '105694852190266',
          },
          {
            id: '156',
            status: 'APPROVED',
            identification: '806614247319731',
            deleted: false,
            name: 'sample_shipping_confirmation',
            language: 'en_US',
            category: 'SHIPPING_UPDATE',
            body: {
              id: '156',
              text: 'Your package has been shipped. It will be delivered in {{1}} business days.',
              example_parameters: [],
            },
            footer: {
              id: '114',
              body: 'This message is from an unverified business.',
            },
            creation_date: '2022-12-19T20:27:13.033',
            last_update: '2022-12-21T02:23:02.133',
            business_id: '105694852190266',
          },
          {
            id: '157',
            status: 'APPROVED',
            identification: '557496575912254',
            deleted: false,
            name: 'sample_shipping_confirmation',
            language: 'es',
            category: 'SHIPPING_UPDATE',
            body: {
              id: '157',
              text: 'ó tu paquete. La entrega se realizará en {{1}} dí.',
              example_parameters: [],
            },
            footer: {
              id: '115',
              body: 'Este mensaje proviene de un negocio no verificado.',
            },
            creation_date: '2022-12-19T20:27:13.036',
            last_update: '2022-12-21T02:23:02.135',
            business_id: '105694852190266',
          },
          {
            id: '158',
            status: 'APPROVED',
            identification: '546153000314171',
            deleted: false,
            name: 'sample_shipping_confirmation',
            language: 'pt_BR',
            category: 'SHIPPING_UPDATE',
            body: {
              id: '158',
              text: 'Seu pacote foi enviado. Ele será entregue em {{1}} dias úteis.',
              example_parameters: [],
            },
            footer: {
              id: '116',
              body: 'Esta mensagem é de uma empresa não verificada.',
            },
            creation_date: '2022-12-19T20:27:13.04',
            last_update: '2022-12-21T02:23:02.138',
            business_id: '105694852190266',
          },
          {
            id: '159',
            status: 'APPROVED',
            identification: '854154459124581',
            deleted: false,
            name: '1_header_image',
            language: 'pt_BR',
            category: 'TRANSACTIONAL',
            header: {
              id: '104',
              type: 'IMAGE',
              body: '',
              example_parameter: 'https://web-api.yup.chat/bucket/52867698-ac7b-4186-a7e9-8525161c8b7f/public/whatsapp/e77a161a-e78d-4bec-ad50-dfd1471cccf5',
            },
            body: {
              id: '159',
              text: 'TESTE',
              example_parameters: [
                '',
              ],
            },
            footer: {
              id: '117',
              body: 'teste',
            },
            creation_date: '2022-12-21T11:51:05.189',
            last_update: '2022-12-21T11:51:05.189',
            business_id: '105694852190266',
          },
        ],
        pageable: {
          sort: {
            sorted: false,
            unsorted: true,
            empty: true,
          },
          pageNumber: 0,
          pageSize: 50,
          offset: 0,
          paged: true,
          unpaged: false,
        },
        last: true,
        totalPages: 1,
        totalElements: 36,
        first: true,
        sort: {
          sorted: false,
          unsorted: true,
          empty: true,
        },
        numberOfElements: 36,
        size: 50,
        number: 0,
        empty: false,
      },

      // recebe string do parametro ou url de um arquivo
      headerParam: '',

      // Temporario para evitar reupload
      headerVideo: null,
      headerDocument: null,
      headerImage: null,

      // Parametros preenchidos pelo usuário
      bodyParams: [],

      uploading: false,
      sending: false,
    };
  },
  created() {
    this.$root.$on('call.crop', this.crop);
  },
  mounted() {
    // this.numbers = this.foo;
    this.currentNumberIndex = 0;
    this.fetchNumbers();
  },
  methods: {
    changeTemplate() {
      this.bodyParams = [];
      this.headerVideo = null;
      this.headerDocument = null;
      this.headerImage = null;
      this.headerParam = '';
    },
    fetchNumbers() {
      this.error = false;
      this.sending = true;

      axios().get('whatsapp/new/numbers').then((response) => {
        response.data.forEach((data) => {
          this.numbers.push(data);
        });
        console.log('WhatsNumbers', response.data, this.numbers);
        this.fetchTemplates();
      }, (error) => {
        this.error = true;
        this.sending = false;
      });
    },
    fetchTemplates() {
      const currentNumber = this.numbers[this.currentNumberIndex];

      console.log({ currentNumber });

      if (currentNumber) {
        let businessId = currentNumber.whatsapp_identification;

        if (!businessId) {
          businessId = currentNumber.phone_number;
        }
        // const phoneNumber = currentNumber.phone_number;

        // this.templates = this.bar.content.filter((entry) => entry.status === 'APPROVED');

        // .map((template) => {
        //   // if (x.body && 'text' in x.body) x.body.text = 'teste {{1}} {{2}}';
        //   // template.businessId = businessId;
        //   // template.phoneNumber = phoneNumber;
        //   return template;
        // });

        WhatsAppService.getNewTemplates(businessId, {
          params: {
            page: 0,
            size: 200,
          },
        }).then((response) => {
          if (response && response.data && response.data.content) {
            this.templates = response.data.content.filter((entry) => entry.status === 'APPROVED');
          } else {
            console.error('invalid templates', response);
          }
          console.log('fetchTemplates', [this.templates, response.data]);
        }, (error) => {
          console.error(error);
          this.error = true;
        }).finally(() => {
          this.sending = false;
        });
      }
    },
    chooseImage(maxWidth, maxHeight) {
      this.$root.$emit('show.modal', 'm-crop');
      const x = this.$store.dispatch('crop/config', {
        maxWidth,
        maxHeight,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });

      console.log({ selectFile: x });
    },
    crop(data) {
      this.$root.$emit('show.modal', 'request-modal');

      console.log('crop', { selectFile: data });

      if (data) this.uploadFile(data);
    },
    selectFile(event) {
      const file = event.target?.files[0];

      console.log('selectFile', { selectFile: file });

      if (file) this.uploadFile(file);
    },
    uploadFile(handle) {
      const type = this.headerType;

      console.log('uploadFile', {
        handle,
        type,
      });

      if (!handle || !type || type === 'TEXT') return;

      const form = new FormData();

      let file;

      if (type === 'IMAGE') {
        const crop = JSON.stringify([{
          x: handle.x,
          y: handle.y,
          w: handle.width,
          h: handle.height,
        }]);

        form.append('crop', crop);

        file = handle.file;
      } else {
        file = handle;
      }

      form.append('files[]', file);
      form.append('role', 'public');
      form.append('folder', 'whatsapp');
      form.append('account_id', this.$store.state.account.id);

      this.uploading = true;

      BucketService.upload(form, true, (progress) => {
        this.uploadProgress = progress;
      }).then((responses) => {
        const url = responses.data[0].url;

        if (type === 'IMAGE') {
          this.headerImage = url;
        } else if (type === 'VIDEO') {
          this.headerVideo = url;
        } else if (type === 'DOCUMENT') {
          this.headerDocument = url;
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.uploading = false;
      });
    },
    sendMessage() {
      const template = this.template;

      console.log('sendMessage');

      if (!this.validForm) {
        console.error('Invalid form');
        return;
      }

      if (!template.name) {
        console.error('Invalid template name');
        return;
      }

      let scheduled = null;

      if (this.scheduled) {
        const parsed = moment(this.scheduledFor, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

        if (!parsed || Number.isNaN(Date.parse(parsed))) {
          console.error('Invalid date', parsed);
          return;
        }

        scheduled = new Date(parsed).toISOString();
      }

      const currentNumber = this.numbers[this.currentNumberIndex];
      const phoneNumber = currentNumber.phone_number;

      let recipientsInput = this.recipients.replace(/(\d)[\\\- ](\d)/, '$1$2');

      recipientsInput = recipientsInput.replace(/[()+]/g, '');

      let invalid = null;
      let recipients = recipientsInput.split(/[;,\r\n]/).map((number) => {
        const cleanNumber = number.trim().replace(/\D+/g, '').replace(/((\r)?\n)+/g, '');

        if (cleanNumber !== '' && cleanNumber.length < 11) invalid = number;

        return cleanNumber;
      });

      if (invalid !== null) {
        this.$toast.show({
          title: 'Erro',
          content: `Telefone inválido: ${invalid}`,
          type: 'danger',
          rejectBtn: null,
          requireInteraction: (accepted) => {},
        });
        this.$emit('error');
        return;
      }

      // Remove duplicate
      recipients = recipients.filter((v, i, a) => v !== '' && a.indexOf(v) === i);

      let headerParam = null;

      // simple:
      // const payload = {
      //   client_number: recipients.join(','),
      //   body_parameters: this.bodyParams,
      //   language_code: template.language,
      //   template_name: template.name,
      //   business_id: template.business_id,
      //   from: phoneNumber,
      //   type: 'template',
      // };

      const payload = {
        client_number: '',
        body_parameters: this.bodyParams,
        business_id: template.business_id,
        language_code: template.language,
        template_id: template.id,
        from: phoneNumber,
      };

      const clientRoomId = this.clientRoomId;

      if (clientRoomId) {
        if (!this.$live.myUserId) {
          this.$toast.show({
            title: 'Erro',
            content: 'não esta conectado ou não é um agent',
            type: 'danger',
            rejectBtn: null,
            requireInteraction: (accepted) => {},
          });
          this.$emit('error');
          return;
        }

        payload.agent_id = this.$live.myUserId;
        payload.account_id = this.$live.accountId;
        payload.client_id = clientRoomId;
        payload.message = this.bodyPreview;
      }

      if (this.headerType === 'TEXT') {
        headerParam = (
          this.headerParam === '' || this.headerParam === false || this.headerParam === null
        ) ? null : this.headerParam;
      } else if (this.headerType === 'VIDEO') {
        headerParam = this.headerCurrentVideo;
      } else if (this.headerType === 'DOCUMENT') {
        headerParam = this.headerCurrentDocument;
      } else if (this.headerType === 'IMAGE') {
        headerParam = this.headerCurrentImage;
      }

      if (headerParam !== null) {
        payload.header_type = this.headerType;
        payload.header_parameter = headerParam;
      }

      if (scheduled) payload.scheduled_to = scheduled;

      this.sending = true;

      const messages = recipients.map((clientNumber) => {
        const clone = JSON.parse(JSON.stringify(payload));
        clone.client_number = clientNumber;
        return clone;
      });

      console.log('sendMessage', messages);

      let req;

      if (clientRoomId) {
        req = this.$live.sendTemplate(messages[0]);
      } else {
        req = axios().post('whatsapp/new/send/templates', { messages });
      }

      req.then((responses) => {
        console.log({ responses });
        this.$toast.show({
          title: 'Sucesso',
          content: 'Mensagem enviada com sucesso!',
          type: 'success',
          rejectBtn: null,
          requireInteraction: (accepted) => {},
        });
        this.$emit('success');
      }).catch((error) => {
        console.error({ error });
        this.$toast.show({
          title: 'Erro',
          content: error,
          type: 'danger',
          rejectBtn: null,
          requireInteraction: (accepted) => {},
        });
        this.$emit('error');
      }).finally(() => {
        this.sending = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  min-height: 200px;

  .qt-loader {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    background: rgba(0,0,0,.32);
    height: 100%;
    width: 100%;

    &:before {
      top: 20%;
      left: 50%;
    }
  }

  .label-send-check {
    margin-left: 10px;
    margin-top: 2px;
  }

  .custom-control-inline {
    margin-right: 0 !important;
    padding-left: 0 !important;
  }
}

.preview {
  border-radius: .2rem;
  background-color: #e5ddd5;
  box-sizing: border-box;
  // min-height: 400px;
  padding: 15px;
  position: relative;

  &::before {
    background: url('/assets/img/chat-bg.png');
    background-size: 366px 666px;
    content: "";
    opacity: .06;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .preview-sample {
    max-width: 340px;
    position: sticky;
    top: 92px;
  }

  .preview-message, .preview-btns .btn {
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
    background-color: #fff;
    border-radius: .32rem;
    width: 100%;
  }

  .preview-btns {
    margin: 0 !important;

    .btn {
      border: none;
      min-height: 32px;
      width: calc(50% - 0.25rem);
      margin: 0.25rem 0.25rem 0.5rem 0;

      &:nth-child(2) {
        margin: 0.25rem 0 0.5rem 0.25rem;
      }

      &.last {
        margin: 0.25rem 0 0.25rem 0;
        width: 100%;
      }
    }
  }

  .preview-message {
    border-top-left-radius: 0;
    padding: .25rem .25rem 0 .25rem;
    position: relative;
    z-index: 2;
    min-height: 32px;

    & > header, & > div, & > footer {
      margin-bottom: .25rem;
      padding: .25rem;
    }

    & > time {
      position: absolute;
      bottom: .25rem;
      right: .5rem;
      font-size: 75%;
    }

    & > footer {
      color: rgb(140, 140, 140);
    }

    & > header {
      overflow: hiddden;
      max-height: 280px;
      max-height: 100%;
      font-weight: bold;

      .media {
        min-height: 100px;
        border-radius: .32rem;
        background: #ececec;
        margin-bottom: .2rem;
        overflow: hidden;

        & > img, & > object, & > video {
          object-fit: cover;
          width: 100%;
        }

        &.document {
          position: relative;
          min-height: 60px;
          height: 60px;

          img {
            position: absolute;
            width: 26px;
            height: 30px;
            left: .5rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
